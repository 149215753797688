import Vue from 'vue'
import Vuex from 'vuex'
import Axios from 'axios';

Vue.use(Vuex)

export const STORE_KEY = '$_partners';

export const store = {
  namespaced: true,
  state: {
    loading: false,
    loaded: false,
    viewBlocked: false,
    partners: [],
    filteredPartners: [],
  },
  getters: {
  },
  mutations: {
    START_LOADING(state) {
      state.loaded = false;
      state.loading = true;
    },
    END_LOADING(state) {
      state.loaded = true;
      state.loading = false;
    },
    SET_DATA(state, data) {
      state.partners = data
    }
  },
  actions: {
    loadData({commit, dispatch}) {
      commit('START_LOADING')
      Axios.get('/api/v2/partners').then((resp) => {
        commit('END_LOADING')
        commit('SET_DATA', resp.data.partners)
        dispatch('filterParners')
      })
    },
    toggleViewBlocked({state}) {
      state.viewBlocked = !state.viewBlocked
    },
    filterParners({state}, viewBlocked) {
      state.filteredPartners = state.partners.filter((item) => {
        return viewBlocked || !item.blocked ? item : null
      })
    }
  }
}
