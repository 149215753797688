
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Requests from '../views/Requests/Requests.vue'
import RequestsView from '../views/RequestsView/RequestsView.vue'
import Partners from '../views/Partners/Partners.vue'
import PartnerEditForm from '../views/PartnerEditForm/PartnerEditForm.vue'
import Programs from '../views/Programs/Programs.vue'
import ProgramEditForm from '../views/ProgramEditForm/ProgramEditForm.vue'
import Settings from '../views/Settings/Settings.vue'
import ProfileSettings from '../views/ProfileSettings/ProfileSettings.vue'

Vue.use(VueRouter)

const routes = [
  {
    // Document title tag
    // We combine it with defaultDocumentTitle set in `src/main.js` on router.afterEach hook
    meta: {
      title: 'Дашборд'
    },
    path: '/',
    name: 'home',
    component: Home
  },
  {
    meta: {
      title: 'Список заявлений'
    },
    path: '/requests',
    name: 'requests',
    component: Requests
  },
  {
    meta: {
      title: 'Список заявлений'
    },
    path: '/requests/:id',
    name: 'requests.view',
    component: RequestsView
  },
  {
    meta: {
      title: 'Список партнеров'
    },
    path: '/partners',
    name: 'partners',
    component: Partners
  },
  {
    meta: {
      title: 'Список партнеров'
    },
    path: '/partners/add',
    name: 'partners.add',
    component: PartnerEditForm
  },
  {
    meta: {
      title: 'Список партнеров'
    },
    path: '/partners/edit/:id',
    name: 'partners.edit',
    component: PartnerEditForm
  },
  {
    meta: {
      title: 'Банковские продукты'
    },
    path: '/programs',
    name: 'programs',
    component: Programs
  },
  {
    meta: {
      title: 'Банковские продукты'
    },
    path: '/programs/add',
    name: 'programs.add',
    component: ProgramEditForm
  },
  {
    meta: {
      title: 'Банковские продукты'
    },
    path: '/programs/edit/:id',
    name: 'programs.edit',
    component: ProgramEditForm
  },
  {
    meta: {
      title: 'Настройки'
    },
    path: '/settings',
    name: 'settings',
    component: Settings
  },
  {
    meta: {
      title: 'Параметры'
    },
    path: '/profile/settings',
    name: 'profile.settings',
    component: ProfileSettings
  },
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
