<template>
  <div>
    <b-input :disabled="disabled" v-model="newValue" v-on:blur="input"></b-input>
  </div>
</template>

<script>
export default {
  name: 'UserAvatar',
  props: {
    row: {
      type: Object,
      default: null
    },
    change: {
      type: Function
    },
    disabled: {
      type: Boolean
    }
  },
  data () {
    console.log(this)
    return {
      newValue: null
    }
  },
  watch: {
    value (newValue) {
      this.newValue = newValue
    }
  },
  created () {
    this.newValue = this._props.row.value
  },
  methods: {
    input () {
      if (this._props.row.value != this.newValue) {
        this._props.row.value = this.newValue
        this.change(this._props.row)
      }
    }
  }
}
</script>
