/* Styles */
import '@/scss/main.scss'

/* Core */
import Vue from 'vue'
import Buefy from 'buefy'
import Axios from 'axios'

/* Router & Store */
import router from './router'
import store from './store'

/* Service Worker */
import './registerServiceWorker'

/* Vue. Main component */
import App from './App.vue'

import {
  Plugins,
} from '@capacitor/core';
const { Storage } = Plugins;

/* Default title tag */
const defaultDocumentTitle = 'Credton'

/* Collapse mobile aside menu on route change & set document title from route meta */
router.afterEach(to => {
  store.commit('asideMobileStateToggle', false)

  if (to.meta && to.meta.title) {
    document.title = `${to.meta.title} — ${defaultDocumentTitle}`
  } else {
    document.title = defaultDocumentTitle
  }
})

Vue.config.productionTip = false

Vue.use(Buefy)

Axios.defaults.baseURL = process.env.VUE_APP_SERVER_ADDRESS
Axios.interceptors.response.use((response) => {
  // Do something with response data
  return response
}, (error) => {
  // Do something with response error
  if ([400, 401, 403].includes(error.response.status)) {
    store.dispatch('logout')
  }

  return Promise.reject(error)
})

Axios.interceptors.request.use((config) => {
  console.log(store.state)
  config.headers['Content-Type'] = 'application/json'
  config.headers.Authorization = 'Bearer ' + store.state.token
  return config
})

Storage.get({ key: 'IDENTITY' }).then((data) => {
  if (data) {
    store.dispatch('prepare', JSON.parse(data.value));
  } else {
    store.dispatch('prepare');
  }
});

store.subscribe((mutation) => {
  switch (mutation.type) {
    case 'SET_IDENTITY': {
      Storage.set({ key: 'IDENTITY', value: JSON.stringify(mutation.payload) });
      break;
    }
  }
});

import numeral from 'numeral';
const numFormat = (numeral) => {
  /**
   * apply a Numeral.js formatting string to an input value
   * @param {Number} val input value to be changed
   * @param {String} [format='0,0'] Numeral.js format string
   * @return {String} formatted output string
   */
  return (val, format = '0,0.00') => numeral(val).format(format);
}
Vue.filter('numFormat', numFormat(numeral));

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault("Asia/Novosibirsk")
Vue.filter('formatDate', (value, format) => {
  if (value) {
    const timeZone = 'Asia/Novosibirsk'
    return dayjs(value).format(format || 'DD.MM.YYYY', {timeZone});
  }
});
Vue.filter('monthsFormat', function(value) {
  let base = value * 1
  if (value > 19) {
    base = value % 10
  }
  if (base == 1) {
    return value + ' месяц';
  } else if (base > 1 && base < 5) {
    return value + ' месяца';
  } else {
    return value + ' месяцев';
  }
});

Vue.filter('toLabel', function(value) {
  const __mapper = {
    'credit': 'Кредит',
    'installment': 'Рассрочка',
    'annuity': 'Аннуитетный',
    'differentiated': 'Дифференцированный'
  }
  return __mapper[value] ? __mapper[value]: value
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
