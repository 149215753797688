<template>
  <div v-if="loaded">
    <title-bar :title-stack="titleStack" />
    <section class="section is-main-section">
      <tiles>
        <card-component
          class="tile is-child"
        >
        <b-field v-for="row in settings" :key="row.id" :label="row.label">
          <component
            :is="row.componentName"
            :row="row"
            :disabled="loadings[row.name]"
            :change="saveSesstings"/>
        </b-field>
        </card-component>
        <card-component
          class="tile is-child"
        >
        </card-component>
      </tiles>
    </section>
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar'
import Tiles from '@/components/Tiles'
import CardComponent from '@/components/CardComponent'
import SettingsStringComponent from '@/components/Settings/SettingsStringComponent'
import SettingsIntComponent from '@/components/Settings/SettingsIntComponent'
import { mapState } from 'vuex'

import {STORE_KEY, store} from './store'

export default {
  name: 'Settings',
  components: {
    CardComponent,
    Tiles,
    TitleBar,
    SettingsStringComponent,
    SettingsIntComponent
  },
  computed: {
    ...mapState(STORE_KEY, [
      'loaded', 'loadings', 'settings'
    ]),
    titleStack () {
      return ['Системное', 'Настройки']
    },
  },
  watch: {
  },
  mounted () {
    this.$store.dispatch(`${STORE_KEY}/loadData`)
  },
  created() {
    if (!(this.$store._modules.root._children[STORE_KEY])) {
      this.$store.registerModule(STORE_KEY, store);
    }
  },
  destroyed() {
    this.$store.unregisterModule(STORE_KEY);
  },
  methods: {
    saveSesstings (row) {
      this.$store.dispatch(`${STORE_KEY}/saveSesstings`, row).then(() => {
        this.$buefy.snackbar.open({
          message: 'Сохранен параметр "' + row.label + '"',
          position: 'is-top',
          type: 'is-success',
          queue: false
        })
      })
    }
  }
}
</script>
