import Vue from 'vue'
import Vuex from 'vuex'
import Axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

Vue.use(Vuex)

export const STORE_KEY = '$_partners_edit_form';


const emptyForm = {
  id: null,
  name: null,
  token: uuidv4(),
  periods: null,
}

export const store = {
  namespaced: true,
  state: {
    loading: false,
    loaded: false,
    form: {}
  },
  getters: {

  },
  mutations: {
    START_LOADING(state) {
      state.loaded = false;
      state.loading = true;
    },
    END_LOADING(state) {
      state.loaded = true;
      state.loading = false;
    },
    SET_PARTNER(state, data) {
      state.form = data
    }
  },
  actions: {
    updateToken({state}) {
      state.form.token = uuidv4()
    },
    loadPartner({commit}, id) {
      commit('START_LOADING');
      console.log(id)
      if (id) {
        Axios.get('/api/v2/partners/' + id).then((resp) => {
          commit('END_LOADING')
          commit('SET_PARTNER', resp.data.partner)
        })
      } else {
        commit('SET_PARTNER', Object.assign({}, emptyForm))
        commit('END_LOADING')
      }
    },
    savePartner({commit, state}) {
      return new Promise((resolve) => {
        commit('START_LOADING');
        if (state.form.id) {
          Axios.patch('/api/v2/partners/' + state.form.id, {partner: state.form}).then((resp) => {
            commit('END_LOADING')
            commit('SET_PARTNER', resp.data.partner)
            resolve()
          })
        } else {
          Axios.post('/api/v2/partners', {partner: state.form}).then((resp) => {
            commit('END_LOADING')
            commit('SET_PARTNER', resp.data.partner)
            resolve()
          })
          commit('END_LOADING')
        }

      })
    },
    blockPartner({commit, state}) {
      return new Promise((resolve) => {
        commit('START_LOADING');
        if (state.form.id) {
          Axios.patch('/api/v2/partners/' + state.form.id, {partner: {blocked: true}}).then((resp) => {
            commit('END_LOADING')
            commit('SET_PARTNER', resp.data.partner)
            resolve()
          })
        }
      })
    },
    unblockPartner({commit, state}) {
      return new Promise((resolve) => {
        commit('START_LOADING');
        if (state.form.id) {
          Axios.patch('/api/v2/partners/' + state.form.id, {partner: {blocked: false}}).then((resp) => {
            commit('END_LOADING')
            commit('SET_PARTNER', resp.data.partner)
            resolve()
          })
        }
      })
    }
  }
}
