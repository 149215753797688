<template>
  <div v-if="loaded">
    <title-bar :title-stack="titleStack">
      <p class="control" v-if="request.state == 'pending'">
        <b-button
          type="is-danger"
          :loading="loading"
          icon-left="cancel"
          @click="rejectRequest"
          >Отклонить</b-button>
        <b-button
          type="is-success"
          :loading="loading"
          icon-left="check"
          @click="acceptRequest"
          >Одобрить</b-button>
      </p>
      <p class="control" v-if="request.state !== 'pending'">
        <b-tag :type="request.state == 'accepted' ? 'is-success' : 'is-danger' ">
          <span v-if="request.state == 'accepted'">Одобрена</span>
          <span v-if="request.state == 'rejected'">Отклонена</span>
          {{request.stated_at | formatDate }}
        </b-tag>
      </p>
    </title-bar>
    <section class="section is-main-section">
      <tiles>
        <card-component
          title="Реквизиты"
          icon="account"
          class="tile is-child"
        >
          <div class="columns">
            <div class="column is-half"><strong>Дал согласие на обработку персональных данных</strong></div>
            <div class="column">
              <span v-if="request.assignOffert">ДА</span>
              <span v-if="!request.assignOffert">НЕТ</span>
            </div>
          </div>
          <div class="columns">
            <div class="column is-half"><strong>ФИО</strong></div>
            <div class="column">{{request.customer.name}}</div>
          </div>
          <div class="columns">
            <div class="column is-half"><strong>Мобильный номер</strong></div>
            <div class="column">{{request.customer.phone}}</div>
          </div>
          <div class="columns">
            <div class="column is-half"><strong>Электронная почта</strong></div>
            <div class="column">{{request.customer.email}}</div>
          </div>
          <div class="columns">
            <div class="column is-half"><strong>Дата и место рождения</strong></div>
            <div class="column">{{request.customer.birthday | formatDate}} {{request.customer.birthplace}}</div>
          </div>
          <div class="columns">
            <div class="column is-half"><strong>Паспорт</strong></div>
            <div class="column">{{request.customer.passport}}</div>
          </div>
          <div class="columns">
            <div class="column is-half"><strong>Адрес прописки</strong></div>
            <div class="column">{{request.customer.addressReg}}</div>
          </div>
          <div class="columns">
            <div class="column is-half"><strong>Адрес регистрации</strong></div>
            <div class="column">{{request.customer.addressProp}}</div>
          </div>
          <div class="columns">
            <div class="column is-half"><strong>Ежемесячный доход</strong></div>
            <div class="column">{{request.monthlyIncome}}</div>
          </div>
        </card-component>
        <div class="tile is-vertical is_parent">
          <card-component
            :title="'Партнер: ' + request.partner.name"
            icon="format-list-bulleted"
            class="tile is-child"
          >
            <div class="columns">
              <div class="column is-half"><strong>Номер телефона</strong></div>
              <div class="column">{{request.partner.phone}}</div>
            </div>
            <div class="columns">
              <div class="column is-half"><strong>Email</strong></div>
              <div class="column">{{request.partner.email}}</div>
            </div>
          </card-component><card-component
            v-if="request.outlet"
            :title="'Точка продажи: ' + request.outlet.name"
            icon="format-list-bulleted"
            class="tile is-child"
          >
            <div class="columns" v-if="request.outlet">
              <div class="column is-half"><strong>Адрес</strong></div>
              <div class="column">{{request.outlet.address}}</div>
            </div>

            <div class="columns" v-if="request.outlet">
              <div class="column is-half"><strong>Описание</strong></div>
              <div class="column">{{request.outlet.description}}</div>
            </div>
          </card-component>
          <card-component
            :title="'Программа: ' + request.program.name"
            icon="cash"
            class="tile is-child"
          >
            <div class="columns">
              <div class="column is-half"><strong>Тип банковского продукта</strong></div>
              <div class="column">{{request.program.type | toLabel}}</div>
            </div>
            <div class="columns">
              <div class="column is-half"><strong>Тип платежа</strong></div>
              <div class="column">{{request.program.payment_type | toLabel}}</div>
            </div>
            <div class="columns">
              <div class="column is-half"><strong>Процентная ставка</strong></div>
              <div class="column">{{request.program.percent}}%</div>
            </div>
          </card-component>
          <card-component
            title="Параметры запроса"
            icon="cash"
            class="tile is-child"
          >
            <div class="columns">
              <div class="column is-half"><strong>Дата создания</strong></div>
              <div class="column">{{request.created_at | formatDate}}</div>
            </div>
            <div class="columns">
              <div class="column is-half"><strong>Программа</strong></div>
              <div class="column">
                <router-link :to="{name: 'programs.edit', params: {id: request.program.id}}">{{request.program.name}}</router-link>
                ({{request.program.percent}}%)
                </div>
            </div>
            <div class="columns">
              <div class="column is-half">
                <strong v-if="request.program.type == 'credit'">Срок кредита</strong>
                <strong v-if="request.program.type == 'installment'">Срок рассрочки</strong>
              </div>
              <div class="column">{{request.period | monthsFormat}}</div>
            </div>
            <div class="columns">
              <div class="column is-half"><strong>Ежемесячный платеж</strong></div>
              <div class="column">{{request.paymentSum | numFormat}}</div>
            </div>
            <div class="columns">
              <div class="column is-half"><strong>Дополнительная информация</strong></div>
              <div class="column">{{request.desc}}</div>
            </div>

          </card-component>
        </div>

        <div class="tile is-vertical is_parent">
          <card-component
            title="Параметры запроса"
            icon="basket"
            class="tile is-child"
          >
            <b-table
                striped
                hoverables
                :data="request.basket.basket.products">
              <b-table-column field="label" label="Название" width="40" v-slot="props">
                  {{ props.row.label }}
              </b-table-column>
              <b-table-column field="label" label="Цена" width="40" v-slot="props">
                  {{ props.row.price | numFormat }}
              </b-table-column>
              <b-table-column field="label" label="Количество" width="40" v-slot="props">
                  {{ props.row.count }}
              </b-table-column>
              <b-table-column field="label" label="Стоимость" width="40" v-slot="props">
                  {{ props.row.price * props.row.count | numFormat }}
              </b-table-column>
              <template #footer>
                  <th colspan="3">
                      <div>ИТОГО</div>
                  </th>
                  <th>
                      {{request.totalSum | numFormat }}
                  </th>
              </template>
            </b-table>
          </card-component>
          <card-component
            title="Документы"
            icon="file"
            class="tile is-child"
          >
          <div v-for="(file, idx) in request.files" :key="idx">
            <a :href="'./uploads/' + request.id + '/' + file" target="_blank">
            <img :src="'./uploads/' + request.id + '/' + file"/>
            </a>
          </div>
          </card-component>
        </div>
      </tiles>
    </section>
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar'
import Tiles from '@/components/Tiles'
import CardComponent from '@/components/CardComponent'
import { mapState } from 'vuex'

import {STORE_KEY, store} from './store'

export default {
  name: 'RequestsView',
  components: {
    CardComponent,
    Tiles,
    TitleBar
  },
  computed: {
    ...mapState(STORE_KEY, [
      'loaded', 'loading', 'request'
    ]),
    titleStack () {
      if (this.request && this.request.customer) {
        return ['Заявки', 'Список', this.request.customer.name]
      }
      return []
    },
  },
  watch: {
  },
  methods: {
    acceptRequest() {
      this.$store.dispatch(`${STORE_KEY}/acceptRequest`)
    },
    rejectRequest() {
      this.$store.dispatch(`${STORE_KEY}/rejectRequest`)
    },
  },
  mounted () {
    this.$store.dispatch(`${STORE_KEY}/loadData`, this.$route.params.id)
  },
  created() {
    if (!(this.$store._modules.root._children[STORE_KEY])) {
      this.$store.registerModule(STORE_KEY, store);
    }
  },
  destroyed() {
    this.$store.unregisterModule(STORE_KEY);
  },
}
</script>
