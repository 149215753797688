<template>
  <div v-if="loaded">
    <title-bar :title-stack="titleStack" />
    <section class="section is-main-section">
      <tiles>
        <card-component
          :title="formCardTitle"
          icon="account-edit"
          class="tile is-child"
        >
          <form @submit.prevent="submit">
            <b-field label="Название" message="Название программы" horizontal>
              <b-input
                v-model="form.name"
                placeholder="Название"
                required
              />
            </b-field>
            <b-field label="Партнер" message="Партнер, для которого работает программа" horizontal>
              <b-select v-model="form.partner" placeholder="выберите партнера">
                <option
                    v-for="option in partners"
                    :value="option"
                    :key="option.id">
                    {{ option.name }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Тип банковского продукта" message="Тип предоставления услуг" horizontal>
              <b-select v-model="form.type" placeholder="выберите тип программы">
                <option value="credit">Кредит</option>
                <option value="installment">Рассрочка</option>
              </b-select>
            </b-field>
            <b-field label="Тип платежа" message="Какой будет платеж" horizontal>
              <b-select v-model="form.payment_type" placeholder="выберите тип программы">
                <option value="annuity">Аннуитетный</option>
                <option value="differentiated">Дифференцированный</option>
              </b-select>
            </b-field>
            <b-field label="Процентная ставка" message="Размер процента за пользование услугой" horizontal>
              <b-numberinput
                v-model="form.percent"
                 step="0.01">
              </b-numberinput>
            </b-field>
            <b-field label="Периоды" message="Периоды предоставления кредита" horizontal>
              <b-input
                v-model="form.periods"
                placeholder="например, 4-6,10-12,24"
              />
            </b-field>
            <b-field>
              <p class="control">
                <b-button
                  type="is-primary"
                  :loading="loading"
                  native-type="submit"
                  >Сохранить</b-button>
              </p>
              <p class="control">
                <b-button
                  v-if="form.blocked"
                  type="is-success"
                  :loading="loading"
                  @click="unblockPartner"
                  >Разблокировать</b-button>
              </p>
              <p class="control">
                <b-button
                  v-if="!form.blocked"
                  type="is-danger"
                  :loading="loading"
                  @click="blockPartner"
                  >Заблокировать</b-button>
              </p>
            </b-field>
          </form>
        </card-component>
      </tiles>
    </section>
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar'
import Tiles from '@/components/Tiles'
import CardComponent from '@/components/CardComponent'
import { mapState } from 'vuex'

import {STORE_KEY, store} from './store'

export default {
  name: 'PartnerEditForm',
  components: {
    CardComponent,
    Tiles,
    TitleBar
  },
  computed: {
    ...mapState(STORE_KEY, [
      'form', 'loaded', 'loading', 'partners'
    ]),
    titleStack () {
      let lastCrumb

      if (this.form.id) {
        lastCrumb = this.form.name
      } else {
        lastCrumb = 'Новый банковский продукт'
      }

      return ['Партнеры', 'Банковские продукты', lastCrumb]
    },
    formCardTitle () {
      if (this.form.id) {
        return 'Редактировать банковский продукт'
      } else {
        return 'Новый банковский продукт'
      }
    }
  },
  watch: {
  },
  mounted () {
    this.$store.dispatch(`${STORE_KEY}/loadProgram`, this.$route.params.id)
  },
  created() {
    if (!(this.$store._modules.root._children[STORE_KEY])) {
      this.$store.registerModule(STORE_KEY, store);
    }
  },
  destroyed() {
    this.$store.unregisterModule(STORE_KEY);
  },
  methods: {
    submit () {
      this.$store.dispatch(`${STORE_KEY}/saveProgram`).then(() => {
        this.$router.push({
          'name': 'programs'
        })
      })
    },
    blockPartner () {
      this.$store.dispatch(`${STORE_KEY}/blockProgram`).then(() => {
        this.$buefy.snackbar.open({
          message: 'Партнер "' + this.form.name + '" заблокирован',
          position: 'is-top',
          type: 'is-success',
          queue: false
        })
      })
    },
    unblockPartner () {
      this.$store.dispatch(`${STORE_KEY}/unblockProgram`).then(() => {
        this.$buefy.snackbar.open({
          message: 'Партнер "' + this.form.name + '" разблокирован',
          position: 'is-top',
          type: 'is-success',
          queue: false
        })
      })
    }
  }
}
</script>
