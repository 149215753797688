<template>
  <div id="app">
    <template v-if="isAuthenticated">
      <nav-bar />
      <aside-menu :menu="menu" />
      <router-view />
    </template>
    <template v-else>
      <LoginForm></LoginForm>
    </template>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar'
import AsideMenu from '@/components/AsideMenu'
import LoginForm from '@/components/Auth/LoginForm'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'Home',
  components: {
    AsideMenu,
    NavBar,
    LoginForm
  },
  computed: {
    ...mapState([
      'token', 'actor'
    ]),
    ...mapGetters([
      'isAuthenticated'
    ]),
    menu () {
      return [
        'Общее',
        [
          {
            to: '/',
            icon: 'desktop-mac',
            label: 'Дашборд'
          }
        ],
        'Заявки',
        [
          {
            to: '/requests',
            icon: 'account-cash',
            label: 'Список'
          }
        ],
        'Партнеры',
        [
          {
            to: '/partners',
            icon: 'format-list-bulleted',
            label: 'Список'
          },
          {
            to: '/programs',
            icon: 'cash',
            label: 'Банковские продукты'
          }
        ],
        'Системное',
        [
          {
            to: '/settings',
            icon: 'settings',
            label: 'Настройки'
          },
        ]
      ]
    }
  }
}
</script>
