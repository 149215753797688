import Vue from 'vue'
import Vuex from 'vuex'
import Axios from 'axios';

Vue.use(Vuex)

export const STORE_KEY = '$_profile_settings';

export const store = {
  namespaced: true,
  state: {
    loading: false,
    loaded: false,
    form: {}
  },
  getters: {

  },
  mutations: {
    START_LOADING(state) {
      state.loaded = false;
      state.loading = true;
    },
    END_LOADING(state) {
      state.loaded = true;
      state.loading = false;
    },
    SET_DATA(state, data) {
      state.form = data;
    },
  },
  actions: {
    loadData({commit}) {
      commit('START_LOADING');
      Promise.all([
        Axios.get('/api/v2/profile')
      ])
      .then(([respSettings]) => {
        commit('END_LOADING')
        commit('SET_DATA', respSettings.data.user)
      })
    },
    saveSesstings({commit, state}) {
      return new Promise((resolve) => {
        commit('START_LOADING');
        const user = {
          name: state.form.name,
        }
        if (state.form.password && state.form.password.length > 0) {
          user.password = state.form.password;
        }
        Axios.patch('/api/v2/profile', {user: user}).then(() => {
          commit('END_LOADING')
          resolve()
        })

      })
    }
  }
}
