<template>
  <div>
    <title-bar :title-stack="titleStack">
      <div class="level-right">
        <div class="level-item">
          <b-button size="is-small" @click="filterOpen = !filterOpen" icon-left="filter"></b-button>
          <b-button size="is-small" @click="exportToExcel()" icon-left="arrow-up"></b-button>
        </div>
      </div>
    </title-bar>
    <section class="section is-main-section">
      <b-collapse
          v-model="filterOpen">
        <div class="columns">
          <div class="column">
            <b-field>
              <b-input placeholder="ФИО клиента" v-model="filter.customer_name"></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field>
              <b-select v-model="filter.partner_id" placeholder="Партнера" expanded>
                <option v-for="partner in partners" :key="partner.id" :value="partner.id">{{partner.name}}</option>
              </b-select>
            </b-field>
          </div>
          <div class="column">
            <b-field>
              <div class="columns">
                <div class="column">
                  <b-datepicker
                    placeholder="Дата от"
                    icon="calendar-today"
                    v-model="filter.date_from">
                  </b-datepicker>
                </div>
                <div class="column">
                  <b-datepicker
                    placeholder="Дата до"
                    icon="calendar-today"
                    v-model="filter.date_to">
                  </b-datepicker>
                </div>
              </div>
            </b-field>
          </div>
          <div class="column is-1">
            <b-field>
              <p class="control"><b-button icon-left="check" type="is-success" outlined @click="setFilter()"></b-button></p>
              <p class="control"><b-button icon-left="close" type="is-danger" outlined @click="clearFilter()"></b-button></p>
            </b-field>
          </div>
        </div>
        <br/>
      </b-collapse>
      <card-component class="has-table has-mobile-sort-spaced">
        <b-table
          :loading="loading"
          striped

          paginated
          backend-pagination
          :total="meta.total_count"
          :per-page="meta.per_page"
          :current-page.sync="meta.page"
          @page-change="onPageChange"
          hoverable
          default-sort="name"
          :data="requests"
        >
          <b-table-column label="ФИО" v-slot="props">
            {{ props.row.customer.name }}
            <br/>
            <b-tag :type="props.row.state == 'accepted' ? 'is-success' : (props.row.state == 'pending' ? 'is-light' : 'is-danger') ">
              <span v-if="props.row.state == 'accepted'">Одобрена</span>
              <span v-if="props.row.state == 'rejected'">Отклонена</span>
              <span v-if="props.row.state == 'pending'">В ожидании</span>
              {{props.row.stated_at | formatDate }}
            </b-tag>
          </b-table-column>
          <b-table-column label="Номер телефона" v-slot="props">
            {{ props.row.customer.phone }}
          </b-table-column>
          <b-table-column label="Дата создания" v-slot="props">
            <small class="has-text-grey is-abbr-like" :title="props.row.created_at">{{ props.row.created_at | formatDate('DD.MM.YYYY HH:mm') }}</small>
          </b-table-column>
          <b-table-column label="Магазин" v-slot="props">
            {{ props.row.partner.name }}
          </b-table-column>
          <b-table-column label="Сумма" field="city" v-slot="props">
            {{ props.row.totalSum | numFormat}}
          </b-table-column>
          <b-table-column label="Срок" field="city" v-slot="props">
            {{ props.row.period | monthsFormat}}
          </b-table-column>
          <b-table-column custom-key="actions" cell-class="is-actions-cell" v-slot="props">
            <div class="buttons is-right">
              <router-link :to="{name:'requests.view', params: {id: props.row.id}}" class="button is-small is-primary">
                <b-icon icon="eye" size="is-small"/>
              </router-link>
            </div>
          </b-table-column>
          <section slot="empty" class="section">
            <div class="content has-text-grey has-text-centered">
              <template v-if="loading">
                <p>
                  <b-icon icon="dots-horizontal" size="is-large" />
                </p>
                <p>Загрузка данных...</p>
              </template>
              <template v-else>
                <p>
                  <b-icon icon="emoticon-sad" size="is-large" />
                </p>
                <p>Нет данных для отображения&hellip;</p>
              </template>
            </div>
          </section>
        </b-table>
      </card-component>
    </section>
  </div>
</template>

<script>
import CardComponent from '@/components/CardComponent'
import TitleBar from '@/components/TitleBar'
import { mapState } from 'vuex'

import {STORE_KEY, store} from './store'

export default {
  name: 'Tables',
  components: {
    TitleBar,
    CardComponent,
  },
  data(){
    return {
      filterOpen: false,
    }
  },
  computed: {
    ...mapState(STORE_KEY, [
      'loading', 'loaded', 'requests', 'filter', 'partners', 'meta'
    ]),
    // ...mapGetters([
    //   'requests'
    // ]),
    titleStack () {
      return ['Заявки', 'Список']
    },
  },
  methods: {
    onPageChange(page) {
      this.meta.page = page
      this.$store.dispatch(`${STORE_KEY}/loadData`, this.filter);
    },
    setFilter() {
      this.$store.dispatch(`${STORE_KEY}/loadData`, this.filter);
    },
    clearFilter() {
      this.$store.dispatch(`${STORE_KEY}/clearFilter`, this.filter);
      this.$store.dispatch(`${STORE_KEY}/loadData`)
    },
    exportToExcel() {
      this.$store.dispatch(`${STORE_KEY}/exportToExcel`, this.filter)
    }
  },
  mounted () {
    console.log(this.$store._modules.root._children)
    this.$store.dispatch(`${STORE_KEY}/loadData`)
  },
  created() {
    if (!(this.$store._modules.root._children[STORE_KEY])) {
      this.$store.registerModule(STORE_KEY, store);
    }
  },
  destroyed() {
    this.$store.unregisterModule(STORE_KEY);
  }
}
</script>
