import Vue from 'vue'
import Vuex from 'vuex'
import Axios from 'axios';

Vue.use(Vuex)

export const STORE_KEY = '$_requests';

const cleanFilter = {
  date_from: null,
  date_to: null,
  customer_name: null,
}

export const store = {
  namespaced: true,
  state: {
    loading: false,
    loaded: false,
    requests: [],
    partners: [],
    meta: {
      pageSize: 1
    },
    filter: {
      date_from: null,
      date_to: null,
      customer_name: null,
    }
  },
  getters: {

  },
  mutations: {
    START_LOADING(state) {
      state.loaded = false;
      state.loading = true;
    },
    END_LOADING(state) {
      state.loaded = true;
      state.loading = false;
    },
    SET_DATA(state, data) {
      state.requests = data.requests
      state.meta = data.meta
    },
    SET_PARTNERS(state, data) {
      state.partners = data
    }
  },
  actions: {
    clearFilter({state}) {
      state.filter = Object.assign({}, cleanFilter)
    },
    loadData({commit, state}, filter) {
      commit('START_LOADING')
      const params = {};
      if (filter) {
        params.date_from = filter.date_from;
        params.date_to = filter.date_to;
        params.customer_name = filter.customer_name;
        params.partner_id = filter.partner_id;
        params.page = state.meta.page;
        params.per_page = state.meta.per_page;
        Axios.get('/api/v2/requests', { params }).then((respRequests) => {
          commit('END_LOADING')
          commit('SET_DATA', respRequests.data)
        })
      } else {
        Promise.all([
          Axios.get('/api/v2/requests'),
          Axios.get('/api/v2/partners')
        ])
        .then(([respRequests, respPartners]) => {
          commit('END_LOADING')
          commit('SET_DATA', respRequests.data)
          commit('SET_PARTNERS', respPartners.data.partners)
        })
      }
    },
    exportToExcel(filter) {
      const params = {};
      if (filter) {
        params.date_from = filter.date_from;
        params.date_to = filter.date_to;
        params.customer_name = filter.customer_name;
        params.partner_id = filter.partner_id;
        Axios.get('/api/v2/requests/xlsx', { params, responseType: 'blob' }, ).then((response) => {
          const label = response.headers['content-disposition'].split('=')[1];
          const url = window.URL.createObjectURL(new Blob([response.data]), {type: ''})
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', label)
          document.body.appendChild(link)
          link.click()
          // URL.revokeObjectURL(link.href)
        })
      }
    },
  }
}
