import Vue from 'vue'
import Vuex from 'vuex'
import Axios from 'axios';

Vue.use(Vuex)

export const STORE_KEY = '$_request_view';

export const store = {
  namespaced: true,
  state: {
    loading: false,
    loaded: false,
    request: {}
  },
  getters: {

  },
  mutations: {
    START_LOADING(state) {
      state.loaded = false;
      state.loading = true;
    },
    END_LOADING(state) {
      state.loaded = true;
      state.loading = false;
    },
    SET_DATA(state, data) {
      state.request = data
    },
    SET_STATE(state, data) {
      state.request.state = data
    }
  },
  actions: {
    loadData({commit}, id) {
      commit('START_LOADING');
      Axios.get('/api/v2/requests/' + id).then((resp) => {
        commit('SET_DATA', resp.data.request)
        commit('END_LOADING')
      })
    },
    acceptRequest({state, commit}) {
      Axios.patch('/api/v2/requests/' + state.request.id + '/state', {state: 'accepted'}).then((resp) => {
        commit('SET_DATA', resp.data.request)
        commit('END_LOADING')
      })
    },
    rejectRequest({state, commit}) {
      Axios.patch('/api/v2/requests/' + state.request.id + '/state', {state: 'rejected'}).then((resp) => {
        commit('SET_DATA', resp.data.request)
        commit('END_LOADING')
      })
    },
  }
}
