import Vue from 'vue'
import Vuex from 'vuex'
import Axios from 'axios';

Vue.use(Vuex)

export const STORE_KEY = '$_settings';

export const store = {
  namespaced: true,
  state: {
    loading: false,
    loadings: {},
    loaded: false,
    settings: []
  },
  getters: {

  },
  mutations: {
    START_LOADING(state) {
      state.loaded = false;
      state.loading = true;
    },
    END_LOADING(state) {
      state.loaded = true;
      state.loading = false;
    },
    START_LOADINGS(state, name) {
      state.loadings[name] = true;
      console.log(1, state.loadings, name)
    },
    END_LOADINGS(state, name) {
      state.loadings[name] = false;
      console.log(2, state.loadings, name)
    },
    SET_DATA(state, data) {
      state.settings = data.map((item) => {
        const type = item.type.charAt(0).toUpperCase() + item.type.slice(1);
        item.componentName = 'Settings' + type + 'Component'
        state.loadings[item.name] = false
        return item
      })
    },
  },
  actions: {
    loadData({commit}) {
      commit('START_LOADING');
      Promise.all([
        Axios.get('/api/v2/settings')
      ])
      .then(([respSettings]) => {
        commit('END_LOADING')
        commit('SET_DATA', respSettings.data.settings)
      })
    },
    saveSesstings({commit}, row) {
      return new Promise((resolve) => {
        commit('START_LOADINGS', row.name);
        const setting = {
          id: row.id,
          name: row.name,
          type: row.type,
          label: row.label,
          value: row.value,
        }
        Axios.patch('/api/v2/settings/' + setting.id, {setting: setting}).then(() => {
          commit('END_LOADINGS', row.name)
          resolve()
        })

      })
    }
  }
}
