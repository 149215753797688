<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar>
      Список банковских продуктов
      <div slot="right">
        <b-field grouped>
          <b-switch @input="filterPrograms($event)">Показывать заблокированных</b-switch>
          <router-link :to="{name:'programs.add'}" class="button">
            Добавить
          </router-link>
        </b-field>
      </div>
    </hero-bar>
    <section class="section is-main-section">
      <card-component class="has-table has-mobile-sort-spaced">
        <b-table
      :loading="loading"
      :striped="true"
      :hoverable="true"
      default-sort="name"
      :data="filteredPrograms"
    >
      <b-table-column label="#" s v-slot="props">
        {{ props.row.id }}
      </b-table-column>
      <b-table-column label="Название" sortable v-slot="props">
        {{ props.row.name }}
        <b-tag v-if="props.row.blocked">заблокирован</b-tag>
      </b-table-column>
      <b-table-column label="Партнер" sortable v-slot="props">
        {{ props.row.partner.name }}
      </b-table-column>
      <b-table-column label="Тип банковского продукта" v-slot="props">
        {{ props.row.type | toLabel }}
      </b-table-column>
      <b-table-column label="Тип платежа" v-slot="props">
        {{ props.row.payment_type | toLabel }}
      </b-table-column>
      <b-table-column label="Процентная ставка" v-slot="props">
        {{ props.row.percent }}%
      </b-table-column>
      <b-table-column label="Дата создания" sortable v-slot="props">
        <small class="has-text-grey is-abbr-like" :title="props.row.created_at">{{ props.row.created_at | formatDate('DD.MM.YYYY hh:mm') }}</small>
      </b-table-column>
      <b-table-column custom-key="actions" cell-class="is-actions-cell" v-slot="props">
        <div class="buttons is-right">
          <router-link :to="{name:'programs.edit', params: {id: props.row.id}}" class="button is-small is-primary">
            <b-icon icon="pencil" size="is-small"/>
          </router-link>
        </div>
      </b-table-column>

      <section slot="empty" class="section">
        <div class="content has-text-grey has-text-centered">
          <template v-if="loading">
            <p>
              <b-icon icon="dots-horizontal" size="is-large" />
            </p>
            <p>Загрузка данных...</p>
          </template>
          <template v-else>
            <p>
              <b-icon icon="emoticon-sad" size="is-large" />
            </p>
            <p>Нет данных для отображения&hellip;</p>
          </template>
        </div>
      </section>
    </b-table>
      </card-component>
    </section>
  </div>
</template>

<script>
import CardComponent from '@/components/CardComponent'
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import { mapState } from 'vuex'

import {STORE_KEY, store} from './store'

export default {
  name: 'Tables',
  components: {
    TitleBar,
    HeroBar,
    CardComponent,
  },
  computed: {
    ...mapState(STORE_KEY, [
      'loading', 'loaded', 'programs', 'filteredPrograms'
    ]),
    titleStack () {
      return ['Партнеры', 'Банковские продукты']
    },

  },
  methods: {
    filterPrograms($event) {
      this.$store.dispatch(`${STORE_KEY}/filterPrograms`, $event)
    }
  },
  mounted () {
    console.log(this.$store._modules.root._children)
    this.$store.dispatch(`${STORE_KEY}/loadData`)
  },
  created() {
    console.log(store)
    if (!(this.$store._modules.root._children[STORE_KEY])) {
      this.$store.registerModule(STORE_KEY, store);
    }
  },
  destroyed() {
    console.log(3)
    this.$store.unregisterModule(STORE_KEY);
  }
}
</script>
