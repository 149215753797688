<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <section v-if="loaded" class="section is-main-section">
      <tiles>
        <card-component
          class="tile is-child"
        >
        <b-field label="Имя пользователя">
          <b-input v-model="form.name"></b-input>
        </b-field>
        </card-component>
        <card-component
          class="tile is-child"
        >
        <b-field label="Пароль" :type="passwordValid === false ? 'is-danger' : ''">
          <b-input type="password" v-model="form.password" v-on:blur="checkPassword"></b-input>
        </b-field>
        <b-field label="Пароль" :type="passwordValid === false ? 'is-danger' : ''">
          <b-input type="password" v-model="form.password_retry" v-on:blur="checkPassword"></b-input>
        </b-field>
        </card-component>
      </tiles>
      <b-button @click="saveSesstings()">Сохранить</b-button>
    </section>
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar'
import Tiles from '@/components/Tiles'
import CardComponent from '@/components/CardComponent'
import { mapState } from 'vuex'

import {STORE_KEY, store} from './store'

export default {
  name: 'ProfileSettings',
  components: {
    CardComponent,
    Tiles,
    TitleBar
  },
  computed: {
    ...mapState(STORE_KEY, [
      'loaded', 'loading', 'form'
    ]),
    titleStack () {
      return ['Профиль', 'Параметры']
    },
  },
  data() {
    return {
      passwordValid: null
    }
  },
  mounted () {
    this.$store.dispatch(`${STORE_KEY}/loadData`)
  },
  created() {
    if (!(this.$store._modules.root._children[STORE_KEY])) {
      this.$store.registerModule(STORE_KEY, store);
    }
  },
  destroyed() {
    this.$store.unregisterModule(STORE_KEY);
  },
  methods: {
    checkPassword() {
      if (this.form.password.length > 0) {
        this.passwordValid = this.form.password == this.form.password_retry
      }
    },
    saveSesstings () {
      this.$store.dispatch(`${STORE_KEY}/saveSesstings`).then(() => {
        this.$buefy.snackbar.open({
          message: 'Параметры сохранены',
          position: 'is-top',
          type: 'is-success',
          queue: false
        })
      })
    }
  }
}
</script>
