import Vue from 'vue'
import Vuex from 'vuex'
import Axios from 'axios';

Vue.use(Vuex)

export default new Vuex.Store({
  namespaced: true,
  state: {
    actor: {},
    token: {}
  },
  getters: {
    isAuthenticated: (state) => {
      return !!state.token
    },
    userName: (state) => {
      return state.actor.name
    }
  },
  mutations: {
    /* Aside Mobile */
    asideMobileStateToggle (state, payload = null) {
      const htmlClassName = 'has-aside-mobile-expanded'

      let isShow

      if (payload !== null) {
        isShow = payload
      } else {
        isShow = !state.isAsideMobileExpanded
      }

      if (isShow) {
        document.documentElement.classList.add(htmlClassName)
      } else {
        document.documentElement.classList.remove(htmlClassName)
      }

      state.isAsideMobileExpanded = isShow
    },
    SET_IDENTITY(state, payload) {
      state.actor = payload.actor;
      state.token = payload.token;
    },
  },
  actions: {
    prepare({ commit }, params) {
      if (params) {
        commit('SET_IDENTITY', params);
      }
    },
    authorize({ commit }, params) {
      return new Promise((resolve, reject) => {
        Axios.post(`/api/v2/token`, params)
        .then((loginResponse) => {
          console.log('loginResponse', loginResponse.data);

          if (loginResponse.data.token) {
            commit('SET_IDENTITY', {
              token: loginResponse.data.token,
              actor: loginResponse.data.actor,
            });

            resolve();
          } else {
            reject(loginResponse.data);
          }
        })
        .catch((error) => {
          if (error.response.status in [401, 403] ) {
            reject(error.response.data);
          }
        });
      });
    },
    logout({ commit }) {
      return new Promise((resolve) => {
        commit('SET_IDENTITY', {
          actor: null,
          token: null,
        });
        resolve();
      });
    },
  }
})
