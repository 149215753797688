import Vue from 'vue'
import Vuex from 'vuex'
import Axios from 'axios';

Vue.use(Vuex)

export const STORE_KEY = '$_programs';

export const store = {
  namespaced: true,
  state: {
    loading: false,
    loaded: false,
    programs: [],
    filteredPrograms: [],
  },
  getters: {

  },
  mutations: {
    SET_DATA(state, data) {
      state.programs = data
    }
  },
  actions: {
    loadData({commit, dispatch}) {
      Axios.get('/api/v2/programs').then((resp) => {
        commit('SET_DATA', resp.data.programs)
        dispatch('filterPrograms')
      })
    },
    filterPrograms({state}, viewBlocked) {
      state.filteredPrograms = state.programs.filter((item) => {
        return viewBlocked || !item.blocked ? item : null
      })
    }
  }
}
