<template>
  <section class="section hero is-fullheight is-error-section">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-two-fifths">
            <div class="card has-card-header-background">

              <header class="card-header">
                <p class="card-header-title">Приветствуем</p>
              </header>

              <div class="card-content">
                <b-field label="Логин" :type="{'is-danger': validationError}" :message="messages[validationError]">
                  <b-input :value="form.login" @change.native="changeLogin($event)"></b-input>
                </b-field>

                <b-field label="Пароль">
                  <b-input type="password" :value="form.password" @change.native="changePass($event)"></b-input>
                </b-field>

                <hr>

                <div class="field is-grouped">
                  <div class="control">
                    <button @click="login(form)" class="button is-black">Далее</button>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="hero-foot has-text-centered">
      <div class="logo"></div>
    </div>
  </section>
</template>

<script lang="ts">
export default {
  data () {
    return {
      validationError: null,
      form: {
        login: '',
        password: ''
      },
      messages: {
        wrong_credentials: 'Не верно заполен логин или пароль'
      }
    }
  },
  mounted () {
    document.documentElement.classList.remove('has-aside-left')
    document.documentElement.classList.remove('has-navbar-fixed-top')
  },
  destroyed () {
    document.documentElement.classList.add('has-aside-left')
    document.documentElement.classList.add('has-navbar-fixed-top')
  },
  methods: {
    changeLogin ($event) {
      this.form.login = $event.target.value
    },
    changePass ($event) {
      this.form.password = $event.target.value
    },
    login (form) {
      console.log(this.$store)
      this.$store.dispatch('authorize', form)
        .then(() => {
          console.log('then')
        })
        .catch((error) => {
          console.log(`catch: ${error}`)

          this.validationError = error.code
        })
    }

  }

}

</script>
