<template>
  <div v-if="loaded">
    <title-bar :title-stack="titleStack" />
    <section class="section is-main-section">
      <tiles>
        <card-component
          :title="formCardTitle"
          icon="account-edit"
          class="tile is-child"
        >
          <form @submit.prevent="submit">
            <b-field label="Название" message="Название магазина или компании" horizontal>
              <b-input
                v-model="form.name"
                placeholder="Название"
                required
              />
            </b-field>
            <b-field label="Токен" message="Униальный токет для авторизации" horizontal>
              <b-input
                v-model="form.token"
                placeholder="***"
                required
                readonly
                icon-right="refresh"
                icon-right-clickable
                @icon-right-click="newToken"
              />
            </b-field>
            <b-field label="Номер телефона" message="Контактный номер телефона" horizontal>
              <b-input
                v-model="form.phone"
                placeholder="+7 (900) 000-00-00"
                required
              />
            </b-field>
            <b-field label="Email" message="Адрес электронной почты" horizontal>
              <b-input
                v-model="form.email"
                placeholder="example@mail.ru"
              />
            </b-field>
            <b-field label="Периоды" message="Периоды предоставления кредита" horizontal>
              <b-input
                v-model="form.periods"
                placeholder="например, 4-6,10-12,24"
                required
              />
            </b-field>
            <b-field label="Ссылка обратной связи" message="Ссылка для ответа о статусе заявки" horizontal>
              <b-input
                v-model="form.callback"
                placeholder="http://example.com/api/callback"
              />
            </b-field>
            <b-field label="Ссылка возврата" message="Ссылка для возврата после отправки заявки " horizontal>
              <b-input
                v-model="form.redirect_to"
                placeholder="http://example.com/redirect"
              />
            </b-field>
            <b-field>
              <p class="control">
                <b-button
                  type="is-primary"
                  :loading="loading"
                  native-type="submit"
                  >Сохранить</b-button>
              </p>
              <p class="control">
                <b-button
                  v-if="form.blocked"
                  type="is-success"
                  :loading="loading"
                  @click="unblockPartner"
                  >Разблокировать</b-button>
              </p>
              <p class="control">
                <b-button
                  v-if="!form.blocked"
                  type="is-danger"
                  :loading="loading"
                  @click="blockPartner"
                  >Заблокировать</b-button>
              </p>
            </b-field>
          </form>
        </card-component>
        <card-component
          title="Точки продажи"
          icon="format-list-bulleted"
          class="tile is-child"
        >
          <b-message
             v-for="outlet in form.outlets" :key="outlet.id"
             >
            <div class="columns">
              <div class="column is-half"><b-tag type="is-danger" v-if="outlet.deleted_at">удалена</b-tag></div>
            </div>
            <div class="columns">
              <div class="column is-half"><strong>Название</strong></div>
              <div class="column">{{outlet.name}}</div>
            </div>
            <div class="columns">
              <div class="column is-half"><strong>Адрес</strong></div>
              <div class="column">{{outlet.address}}</div>
            </div>
            <div class="columns">
              <div class="column is-half"><strong>Описание</strong></div>
              <div class="column">{{outlet.description}}</div>
            </div>
        </b-message>
        </card-component>
      </tiles>
    </section>
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar'
import Tiles from '@/components/Tiles'
import CardComponent from '@/components/CardComponent'
import { mapState } from 'vuex'

import {STORE_KEY, store} from './store'

export default {
  name: 'PartnerEditForm',
  components: {
    CardComponent,
    Tiles,
    TitleBar
  },
  data () {
    return {
      isLoading: false,
      createdReadable: null,
      isProfileExists: false
    }
  },
  computed: {
    ...mapState(STORE_KEY, [
      'form', 'loaded', 'loading'
    ]),
    titleStack () {
      let lastCrumb

      if (this.form.id) {
        lastCrumb = this.form.name
      } else {
        lastCrumb = 'Новый партнер'
      }

      return ['Партнеры', 'Список', lastCrumb]
    },
    formCardTitle () {
      if (this.form.id) {
        return 'Редактировать партнера'
      } else {
        return 'Новый партнер'
      }
    }
  },
  watch: {
    // id (newValue) {
    //   this.isProfileExists = false

    //   if (!newValue) {
    //     this.form = this.getClearFormObject()
    //   } else {
    //     this.getData()
    //   }
    // }
  },
  mounted () {
    this.$store.dispatch(`${STORE_KEY}/loadPartner`, this.$route.params.id)
  },
  created() {
    if (!(this.$store._modules.root._children[STORE_KEY])) {
      this.$store.registerModule(STORE_KEY, store);
    }
  },
  destroyed() {
    this.$store.unregisterModule(STORE_KEY);
  },
  methods: {
    newToken() {
      this.$buefy.dialog.confirm({
        message: "Вы уверены, что надо обновить токен?",
        cancelText: 'Нет',
        confirmText: 'Да',
        onConfirm: () => {
          this.$store.dispatch(`${STORE_KEY}/updateToken`)
        }
      })
    },
    submit () {
      this.$store.dispatch(`${STORE_KEY}/savePartner`).then(() => {
        this.$router.push({
          'name': 'partners'
        })
      })
    },
    blockPartner () {
      this.$store.dispatch(`${STORE_KEY}/blockPartner`).then(() => {
        this.$buefy.snackbar.open({
          message: 'Партнер "' + this.form.name + '" заблокирован',
          position: 'is-top',
          type: 'is-success',
          queue: false
        })
      })
    },
    unblockPartner () {
      this.$store.dispatch(`${STORE_KEY}/unblockPartner`).then(() => {
        this.$buefy.snackbar.open({
          message: 'Партнер "' + this.form.name + '" разблокирован',
          position: 'is-top',
          type: 'is-success',
          queue: false
        })
      })
    }
  }
}
</script>
