<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar :has-right-visible="false">
      Дашборд
    </hero-bar>
    <section class="section is-main-section">
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
export default {
  name: 'Home',
  components: {
    HeroBar,
    TitleBar
  },
  data () {
    return {
    }
  },
  computed: {
    titleStack () {
      return ['Общее', 'Дашборд']
    }
  },
  mounted () {
  },
  methods: {
  }
}
</script>
